.no-content label::after {
  background: tomato;
  content: "~";
}

.site-card-border-less-wrapper {
  padding: 30px;
}

.ant-picker {
  padding: 15px;
  width: 100%;
}

.layout-content {
  padding: 10px 20px;
}

/* table */
.fixed_headers {
  /* width: 750px; */
  table-layout: fixed;
  border-collapse: collapse;
}
.fixed_headers th {
  text-decoration: underline;
}
.fixed_headers th,
.fixed_headers td {
  padding: 5px;
  text-align: left;
}
.fixed_headers td:nth-child(1),
.fixed_headers th:nth-child(1) {
  min-width: 200px;
}
.fixed_headers td:nth-child(2),
.fixed_headers th:nth-child(2) {
  min-width: 200px;
}
.fixed_headers td:nth-child(3),
.fixed_headers th:nth-child(3) {
  width: 350px;
}
.fixed_headers thead {
  background-color: #333;
  color: #fdfdfd;
}
.fixed_headers thead tr {
  display: block;
  position: relative;
}
.fixed_headers tbody {
  display: block;
  overflow: auto;
  width: 100%;
  /* height: 300px; */
}
.fixed_headers tbody tr:nth-child(even) {
  background-color: #ddd;
}
.old_ie_wrapper {
  /* height: 300px; */
  width: 750px;
  overflow-x: hidden;
  overflow-y: auto;
}
.old_ie_wrapper tbody {
  height: auto;
}

/* .custom-row-hover:hover tbody tr {background: rgb(170, 255, 198)!important;} */
.ant-table-cell-row-hover {
  background: rgb(172, 172, 172) !important;
}

.table-transactions tr:nth-child(even) {
  background: #dcdcdc;
}
.table-transactions tr:nth-child(odd) {
  background: #fff;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
    /* overflow: hidden !important; */
  }

  root *,
  body,
  html {
    font-size: 12pt;
    size: A4 portrait;
    overflow: hidden !important;
  }
  .printable-content {
    display: block;
    overflow: hidden !important;
  }
  @page {
    font-size: 12pt;
    size: A4 portrait;
    margin: 50%;
  }
  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 0, 0, 0);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 0, 0, 0);
  }
}

.download-table-xls-button {
  background: transparent;
  border: none;
}

table#table-to-xls tr:nth-child(even) {
  background: #eee;
}
table#table-to-xls td:last-child tr:not(thead) {
  background: #efefef;
}

.new-row {
  background: #ffcb9a77;
}
.old-row {
  background: #d2e8e300;
}

.hover-column tr:hover {
  background: red;
}

.fade-in {
  -webkit-animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.puff-in-center {
  -webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
    both;
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
